import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format } from '@msdyn365-commerce-modules/utilities';
import {
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    Image,
    msdyn365Commerce
} from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { PriceComponent } from '@msdyn365-commerce/components';
import { CommerceProperty } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g.d';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
}

export interface IProductComponent extends React.Component<IProductComponentProps> { }

const PriceComponentActions = {};

/**
 *
 * The ProductCard component.
 * @extends {React.PureComponent<IProductComponentProps>}
 */
class ProductCard extends React.Component<IProductComponentProps> {
    constructor(props: IProductComponentProps) {
        super(props);
        this.SearchProductTracking = this.SearchProductTracking.bind(this);
    }

    public SearchProductTracking(): void {
        const query = new URLSearchParams(document.location.search);
        if (query.has('q')) {
            (Window as any).digitalData.event.push(
                {
                    eventName: 'SearchProductSelect',
                    eventAction: 'SearchProductSelect',
                    timeStamp: new Date(),
                    location: document.location.href,
                    attributes: {}
                }
            );
            sessionStorage.setItem('findingMethod', 'internal search');
        }
        if (query.has('refiners')) {
            (Window as any).digitalData.event.push(
                {
                    eventName: 'FilteredProductSelect',
                    eventAction: 'FilteredProductSelect',
                    timeStamp: new Date(),
                    location: document.location.href,
                    attributes: {}
                }
            );
            sessionStorage.setItem('findingMethod', 'category filter');
        }
    }

    public render(): JSX.Element {
        const {
            data,
            context,
            imageSettings,
            savingsText,
            freePriceText,
            originalPriceText,
            currentPriceText,
            ratingAriaLabel,
            allowBack,
            typeName,
            id
        } = this.props;

        const product = data.product;
        if (!product) {
            return <></>;
        }

        let productUrl = getProductPageUrlSync(
            product.Name || '',
            product.RecordId,
            context && context.actionContext,
            undefined
        );
        if (allowBack) {
            productUrl = this._updateProductUrl(productUrl, context);
        }
        const priceData = this.props.data.product?.ExtensionProperties;
        const priceIncGST = priceData?.find((prop: CommerceProperty) => prop.Key === 'BasePriceWithGST')?.Value
            ?.DecimalValue;
        const specialPriceIncGST = priceData?.find((prop: CommerceProperty) => prop.Key === 'ItemPriceWithGST')?.Value
            ?.DecimalValue;
        // Construct telemetry attribute to render
        const attribute =
            context &&
            context.telemetry &&
            context.telemetry.setTelemetryAttribute &&
            context.telemetry.setTelemetryAttribute(product.RecordId.toString(), {
                pid: product.RecordId,
                pname: product.Name,
                mname: id
            });

        return (
            <a
                href={productUrl}
                aria-label={this._renderLabel(
                    product.Name,
                    context.cultureFormatter.formatCurrency(product.Price),
                    product.AverageRating,
                    ratingAriaLabel
                )}
                className='msc-product'
                {...attribute}
                onClick={this.SearchProductTracking}
            >
                <div className='msc-product__image'>
                    {this._renderProductPlacementImage(
                        imageSettings,
                        context.request.gridSettings,
                        product.PrimaryImageUrl,
                        product.Name
                    )}
                </div>
                <div className='msc-product__details'>
                    <h4 className='msc-product__title'>{product.Name}</h4>
                    {priceData &&
                        this._renderPrice(
                            context,
                            typeName,
                            id,
                            priceIncGST,
                            specialPriceIncGST,
                            savingsText,
                            freePriceText,
                            originalPriceText,
                            currentPriceText
                        )}
                    {this._renderDescription(product.Description)}
                    {/* Commenting out ratings, as out of scope for current delivery */}
                    {/* {renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)} */}
                </div>
            </a>
        );
    }

    private _renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
        name = name || '';
        price = price || '';
        return `${name} ${price} ${this._getRatingAriaLabel(rating, ratingAriaLabel)}`;
    }

    private _renderDescription(description?: string): JSX.Element | null {
        return <p className='msc-product__text'>{description}</p>;
    }

    private _getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
        if (rating && ratingAriaLabel) {
            const roundedRating = rating.toFixed(2);
            return format(ratingAriaLabel || '', roundedRating, '5');
        }
        return '';
    }

    private _updateProductUrl(productUrl: string, context: ICoreContext): string {
        const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
        const queryString = `back=true`;
        if (srcUrl.search) {
            srcUrl.search += `&${queryString}`;
        } else {
            srcUrl.search += queryString;
        }

        const updatedUrl = new URL(srcUrl.href);
        return updatedUrl.pathname + srcUrl.search;
    }

    // Rating out of scope for this part of the project, commenting block out
    //
    // private _renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
    //     if (!avgRating) {
    //         return null;
    //     }

    //     const numRatings = totalRatings && totalRatings.toString() || undefined;
    //     const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

    //     return (
    //         <RatingComponent
    //             context={context}
    //             id={id}
    //             typeName={typeName}
    //             avgRating={avgRating}
    //             ratingCount={numRatings}
    //             readOnly={true}
    //             ariaLabel={ratingAriaLabel}
    //             data={{}}
    //         />
    //     );
    // }

    private _renderPrice(
        context: ICoreContext,
        typeName: string,
        id: string,
        basePrice?: number,
        adjustedPrice?: number,
        savingsText?: string,
        freePriceText?: string,
        originalPriceText?: string,
        currentPriceText?: string
    ): JSX.Element {
        const price: ProductPrice = {
            BasePrice: basePrice,
            AdjustedPrice: adjustedPrice,
            CustomerContextualPrice: adjustedPrice
        };

        return (
            <PriceComponent
                context={context}
                id={id}
                typeName={typeName}
                data={{ price: price }}
                savingsText={savingsText}
                freePriceText={freePriceText}
                originalPriceText={originalPriceText}
            />
        );
    }

    private _renderProductPlacementImage(
        imageSettings?: IImageSettings,
        gridSettings?: IGridSettings,
        imageUrl?: string,
        altText?: string
    ): JSX.Element | null {
        if (!imageUrl || !gridSettings || !imageSettings) {
            return null;
        }
        const img: IImageData = {
            src: imageUrl,
            altText: altText ? altText : ''
        };
        const imageProps = {
            gridSettings: gridSettings,
            imageSettings: imageSettings
        };
        return <Image {...img} {...imageProps} loadFailureBehavior='empty' />;
    }
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<IProductComponent>(
    'Product',
    { component: ProductCard, ...PriceComponentActions }
);

export default ProductComponent;
