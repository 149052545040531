/* tslint:disable: no-duplicate-imports */
import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import {
    Alert,
    Button,
    getPayloadObject,
    getTelemetryAttributes,
    ITelemetryContent
} from '@msdyn365-commerce-modules/utilities';
import { PriceComponent } from '@msdyn365-commerce/components';
// import { PriceComponent } from '../../../themes/farmlandstheme/views/components/price.component';
import { ICoreContext, IImageSettings, Image, ITelemetry } from '@msdyn365-commerce/core';
import {
    CommerceListLine,
    CommerceProperty,
    ProductPrice,
    ReleasedProductType,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as React from 'react';
import { IWishlistItemActionMessageState } from '../farmlands-wishlist-items';
import { WishlistItemDimension } from './farmlands-wishlist-items-dimensions';

export interface IWishlistItemProps extends CommerceListLine {
    product: SimpleProduct;
    wishlistState: IWishlistItemActionMessageState;
    price?: ProductPrice;
    context: ICoreContext;
    productUrl: string;
    imageSettings?: IImageSettings;
    removeFromWishlistText: string;
    isInStock: boolean;
    addToCartText: string;
    outOfStockText: string;
    index: number;
    itemKey: string;
    telemetry: ITelemetry;
    moduleId: string;
    moduleTypeName: string;
    enableImageProductLink?: boolean;
    inventoryInformationLabel?: string;
    inventoryLabelClassName?: string;
    telemetryContent?: ITelemetryContent;
    handlers: {
        onAddToCart(product: SimpleProduct, itemKey: string): void;
        onRemoveItem(productId: number): void | undefined;
        onDismiss(): void;
    };
    resources: {
        productDimensionTypeColor: string;
        productDimensionTypeSize: string;
        productDimensionTypeStyle: string;
        originalPriceText: string;
        currentPriceText: string;
        freePriceText: string;
    };
}

export interface IWishlistItemViewProps {
    key: string;
    productImage: React.ReactNode;
    productLink: React.ReactNode;
    productDimensions?: React.ReactNode[];
    productPrice?: React.ReactNode;
    addToCartButton?: React.ReactNode;
    removeButton?: React.ReactNode;
    productStatusMessage?: React.ReactNode;
    productName?: React.ReactNode;
    productAriaLabel?: string;
    entireProductLink?: string;
    inventoryInformation?: React.ReactNode;
}

const _addItemToCart = (props: IWishlistItemProps): void => {
    const { product, itemKey, handlers } = props;
    const { onAddToCart } = handlers;

    onAddToCart && product && itemKey && onAddToCart(product, itemKey);
};

const _removeItemFromWishlist = (props: IWishlistItemProps): void => {
    const { onRemoveItem } = props.handlers;
    const { RecordId } = props.product;

    onRemoveItem && RecordId && onRemoveItem(RecordId);
};

const WishListItemActions = {
    addToCart: _addItemToCart,
    removeItem: _removeItemFromWishlist
};

// tslint:disable-next-line:cyclomatic-complexity
export const WishlistItem = (input: IWishlistItemProps): IWishlistItemViewProps | null => {
    const {
        product,
        productUrl,
        price,
        context,
        imageSettings,
        isInStock,
        removeFromWishlistText,
        addToCartText,
        outOfStockText,
        wishlistState,
        itemKey,
        handlers,
        enableImageProductLink,
        inventoryInformationLabel,
        inventoryLabelClassName,
        telemetryContent
    } = input;

    if (!product) {
        return null;
    }

    const { RecordId, Name, PrimaryImageUrl, Dimensions } = product;

    const addItemAction = (event: React.MouseEvent<HTMLElement>) => {
        (Window as any).digitalData.product = [{
            productInfo: {
                productName: product.Name,
                price: product.Price,
                sku: product.ItemId,
            }
        }];
        window.dispatchEvent(new CustomEvent('AddToCart'));
        return WishListItemActions.addToCart(input);
    };
    const removeItemAction = (event: React.MouseEvent<HTMLElement>) => {
        return WishListItemActions.removeItem(input);
    };
    const trackAnalytics = () => {
        sessionStorage.setItem('findingMethod', 'wishlist');
    }
    const { onDismiss } = handlers;
    const inventoryCssName = inventoryLabelClassName
        ? `ms-wishlist-items__inventory-label ${inventoryLabelClassName}`
        : 'ms-wishlist-items__inventory-label';
    const fallbackImage = getFallbackImageUrl(product.ItemId, context.actionContext.requestContext.apiSettings);
    const payLoad = getPayloadObject('click', telemetryContent!, '', RecordId.toString());
    const productAttributes = getTelemetryAttributes(telemetryContent!, payLoad);

    // check if the product is service or not by product type
    const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
    const isServiceItem = product.ItemTypeValue === PRODUCTASSERVICE;

    return {
        key: `${RecordId}-item`,
        productImage:
            PrimaryImageUrl &&
            Name &&
            _renderProductImage(context, product.Name!, PrimaryImageUrl, fallbackImage, imageSettings),
        productLink: productUrl && (
            <a
                href={productUrl}
                className='ms-wishlist-items__product-link'
                {...productAttributes}
                aria-label={_renderAriaLabel(input)}
                onClick={trackAnalytics}
            >
                {Name}
            </a>
        ),
        productDimensions: Dimensions && _renderProductDimensions(input),
        productPrice: price && _renderPrice(input),
        entireProductLink: enableImageProductLink ? productUrl : undefined,
        productAriaLabel: enableImageProductLink ? _renderAriaLabel(input) : undefined,
        productName: enableImageProductLink && <div className='ms-wishlist-items__product-title'>{Name}</div>,

        addToCartButton: (
            <Button
                className='ms-wishlist-items__product-add-button'
                title={((isServiceItem || isInStock) && addToCartText) || outOfStockText}
                onClick={addItemAction}
                disabled={!(isServiceItem || isInStock)}
            >
                {((isServiceItem || isInStock) && addToCartText) || outOfStockText}
            </Button>
        ),
        inventoryInformation: inventoryInformationLabel && (
            <span className={inventoryCssName}>{inventoryInformationLabel}</span>
        ),
        removeButton: (
            <button
                className='ms-wishlist-items__product-remove-button'
                aria-label={removeFromWishlistText}
                onClick={removeItemAction}
            />
        ),
        productStatusMessage: wishlistState &&
            wishlistState.isOpen &&
            wishlistState.productId === RecordId &&
            wishlistState.itemKey === itemKey && (
                <div className='ms-wishlist-items__product-status'>
                    <Alert color={wishlistState.statusCssString} isOpen={wishlistState.isOpen} toggle={onDismiss}>
                        <span>{wishlistState.userMessage}</span>
                    </Alert>
                </div>
            )
    };
};

const _renderProductImage = (
    context: ICoreContext,
    heading: string,
    image?: string,
    fallbackImage?: string,
    imageSettings?: IImageSettings
): JSX.Element | null => {
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=315&h=315&m=6`, w: 0, h: 0 },
            lg: { q: `w=315&h=315&m=6`, w: 0, h: 0 },
            xl: { q: `w=315&h=315&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    if (image) {
        return (
            <Image
                className='ms-wishlist-items__product-image'
                altText={heading}
                title={heading}
                src={image}
                fallBackSrc={fallbackImage}
                gridSettings={context.request.gridSettings!}
                imageSettings={imageSettings || defaultImageSettings}
                loadFailureBehavior='empty'
            />
        );
    }
    return null;
};

const _renderPrice = (props: IWishlistItemProps): JSX.Element | null => {
    const { price, context, moduleId, moduleTypeName, resources } = props;
    const priceIncGST = price?.ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === 'BasePriceWithGST')
        ?.Value?.DecimalValue;
    const specialPriceIncGST = price?.ExtensionProperties!.find(
        (prop: CommerceProperty) => prop.Key === 'ItemPriceWithGST'
    )?.Value?.DecimalValue;
    if (price) {
        return (
            <PriceComponent
                id={moduleId}
                typeName={moduleTypeName}
                data={{
                    price: {
                        BasePrice: priceIncGST,
                        AdjustedPrice: specialPriceIncGST,
                        CustomerContextualPrice: specialPriceIncGST
                    }
                }}
                className='ms-wishlist-items__product-price'
                freePriceText={resources.freePriceText}
                originalPriceText={resources.originalPriceText}
                currentPriceText={resources.currentPriceText}
                context={context}
            />
        );
    }
    return null;
};

const _renderProductDimensions = (props: IWishlistItemProps): (React.ReactNode | undefined)[] => {
    const { product, resources } = props;

    if (!product || !product.Dimensions) {
        return [];
    }

    return product.Dimensions.map((dimension, index) => {
        if (!dimension.DimensionValue || !dimension.DimensionValue.Value) {
            return undefined;
        }

        let type = '';

        switch (dimension.DimensionTypeValue) {
            case 1: {
                type = resources.productDimensionTypeColor;
                break;
            }
            case 3: {
                type = resources.productDimensionTypeSize;
                break;
            }
            case 4: {
                type = resources.productDimensionTypeStyle;
                break;
            }
            default:
                return undefined;
        }

        return (
            <WishlistItemDimension
                key={`${product.RecordId}-dimensions-${index}`}
                className='msc-price ms-wishlist-items__product-dimension'
                dimensionType={type}
                dimensionValue={dimension.DimensionValue.Value}
            />
        );
    });
};

const _renderAriaLabel = (props: IWishlistItemProps): string => {
    const { product, price, context, resources } = props;

    const { Name, Dimensions } = product;
    const { productDimensionTypeColor, productDimensionTypeSize, productDimensionTypeStyle } = resources;

    let formattedPrice = 'Free';
    let dimensions = null;

    if (price) {
        formattedPrice =
            price.BasePrice === 0
                ? resources.freePriceText
                : context.cultureFormatter.formatCurrency(price.BasePrice!, price.CurrencyCode);
    }

    if (Dimensions) {
        dimensions = Dimensions.map((productDimension) => {
            switch (productDimension.DimensionTypeValue) {
                case 1:
                    return `${productDimensionTypeColor}${productDimension.DimensionValue && productDimension.DimensionValue.Value
                        }`;
                case 3:
                    return `${productDimensionTypeSize}${productDimension.DimensionValue && productDimension.DimensionValue.Value
                        }`;
                case 4:
                    return `${productDimensionTypeStyle}${productDimension.DimensionValue && productDimension.DimensionValue.Value
                        }`;
                default:
                    return '';
            }
        });
    }

    return `${Name} ${formattedPrice} ${(dimensions && dimensions.join('')) || ''}`;
};
